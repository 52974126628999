@import 'colors';
@import 'typography';
@import 'mixins';
@import 'variables';

// MAIN THEME VARIABLES
$backgroundColor: $almost_white; //background color of tabulator
$borderColor: $almost_white; //border to tabulator
//$textSize:14px; //table text size

// HEADER THEMING
$headerBackgroundColor: $bg_grey_2; //border to tabulator
//$headerTextColor:#555; //header text color
$headerBorderColor: $almost_white; //header border color
$headerSeparatorColor: $almost_white; //header bottom separator color
//$headerMargin:4px; //padding round header

// COLUMN HEADER ARROWS
//$sortArrowHover: #555;
//$sortArrowActive: #666;
//$sortArrowInactive: #bbb;

//$columnResizeGuideColor:#999;

// ROW THEMING
$rowBackgroundColor: $almost_white; //table row background color
//$rowAltBackgroundColor:#EFEFEF; //table row background color
$rowBorderColor: $almost_white; //table border color
//$rowTextColor:#333; //table text color
//$rowHoverBackground:#bbb; //row background color on hover

//$rowSelectedBackground: #9ABCEA; //row background color when selected
//$rowSelectedBackgroundHover: #769BCC;//row background color when selected and hovered

//$editBoxColor:#1D68CD; //border color for edit boxes
//$errorColor:#dd0000; //error indication

// FOOTER THEMING
//$footerBackgroundColor:#e6e6e6; //border to tabulator
//$footerTextColor:#555; //footer text color
//$footerBorderColor:#aaa; //footer border color
//$footerSeparatorColor:#999; //footer bottom separator color
//$footerActiveColor:#d00; //footer bottom active text color

//$spreadsheetActiveTabColor:#fff; //color for the active spreadsheet tab

// RANGE SELECTION
//$rangeBorderColor: #2975DD; //range border color
//$rangeHandleColor: $rangeBorderColor; //range handle color
//$rangeHeaderSelectedBackground: #3876ca; //header background color when selected
//$rangeHeaderSelectedTextColor: #FFFFFF; //header text color when selected
//$rangeHeaderHighlightBackground: #D6D6D6; //header background color when highlighted
//$rangeHeaderTextHighlightBackground: #000000; //header text color when highlighted

@import 'tabulator-tables/src/scss/tabulator.scss';

.table-nav {
    @include flex-space-between;
}

.filter-btn {
    @include flex-centered-items;
    @include font-style(LatoItalic, 1.4rem, $almost_black);
    background: #fff;
    border: none;
    margin-right: 15px;
    padding: 0;

    > span {
        padding: 7px 14px;
    }

    &.active {
        background: $green;

        .filter {
            background: $hover_btn_txt;
        }

        & + .form-fields {
            display: flex !important;
            transform: scaleY(1) !important;
        }
    }
}

.tabulator {
    border-left: 4px solid $bg_grey_2;
    border-right: 4px solid $bg_grey_2;
    border-bottom: 8px solid $bg_grey_2;
}

.tabulator-header {
    .tabulator-header-contents {
        @include flex-stretched-items;
        height: 50px;
    }
    .tabulator-headers {
        display: flex !important;
        height: 100% !important;

        .tabulator-col {
            @include flex-centered-vertically-items;
            height: 100% !important;
            text-transform: uppercase;
        }

        .tabulator-col-title {
            @include font-style(LatoBold, 1.2rem, $almost_black);
            padding-left: 15px;
        }
    }
}

.tabulator-table {
    .tabulator-row {
        .tabulator-cell {
            @include flex-centered-items(true);
            @include font-style(LatoRegular, 1.4rem, $almost_black);
            padding: 4px 11px;
        }
    }
}

.tabulator-menu {
    .tabulator-menu-item {
        @include font-style(LatoRegular, 1.4rem, $almost_black);
        padding: 9px 14px;

        &:not(.tabulator-menu-item-disabled):hover {
            background: rgba($footer, 0.2);
        }
    }
}

.tabulator
    .tabulator-tableholder
    .tabulator-placeholder
    .tabulator-placeholder-contents {
    @include flex-full-centered-items;
    @include font-style(LatoBlackItalic, 5rem !important, #fff !important);

    .clear-btn {
        @extend %menu-btn-style;
        margin-left: 20px;
    }
}

.nested-table {
    .tabulator-table {
        .tabulator-row {
            .tabulator-cell {
                @include flex-centered-items(true);
                @include font-style(LatoRegular, 1.4rem, $almost_black);
                padding: 8px 11px;
            }
        }
    }
}

.btn-table-menu {
    @include flex-full-centered-items;
    @include border-radius(4px);
    width: 32px;
    height: 32px;
    color: $hover_btn_txt;
    background: #fff;
    border: 0;
    margin: 0 auto;

    &:hover {
        background: $hover_btn_txt;
        color: #fff;
    }

    &.collapse {
        width: 26px;
        height: 26px;

        > span {
            font-size: 1rem;
        }

        &.active {
            background: $green;
            color: $almost_black;

            > span {
                transform: rotate(-90deg);
            }
        }
    }
}

.nested-table-container {
    box-sizing: border-box;
    padding: 2rem 2rem 2rem 60px;

    &.hidden {
        display: none;
    }

    .tabulator {
        border-left: 4px solid $bg_grey_2;
        border-right: 4px solid $bg_grey_2;
        border-bottom: 8px solid $bg_grey_2;
    }

    .tabulator-header {
        .tabulator-header-contents {
            height: 35px;
        }

        .tabulator-headers {
            .tabulator-col {
                text-transform: initial;
            }
        }
    }
}

.table-tabs {
    display: flex;
    margin-bottom: -1px;
    .tab {
        @include font-style(LatoRegular, 1.4rem, $almost_black);
        text-transform: uppercase;
        border: none;
        background: transparent;
        padding: 1.4rem 2rem;
        z-index: 2;

        &.active {
            @include font-style(LatoBold, 1.4rem, $almost_black);
            background: $bg_grey_2;
            border-top: 1px solid #ffffff;
        }

        &:hover {
            background: $bg_grey_2;
            border-top: 1px solid #ffffff;
        }
    }
}
