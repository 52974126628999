@import 'variables';

@font-face {
    font-family: Lato-Regular;
    src: url(../fonts/Lato-Regular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: Lato-Medium;
    src: url(../fonts/Lato-Medium.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: Lato-Bold;
    src: url(../fonts/Lato-Bold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: Lato-BoldItalic;
    src: url(../fonts/Lato-BoldItalic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: Lato-Italic;
    src: url(../fonts/Lato-Italic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: Lato-BlackItalic;
    src: url(../fonts/Lato-BlackItalic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: Lato-Black;
    src: url(../fonts/Lato-Black.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: Lato-LightItalic;
    src: url(../fonts/Lato-LightItalic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: Lato-Light;
    src: url(../fonts/Lato-Light.ttf) format('truetype');
    font-display: swap;
}

@mixin LatoLightItalic {
    font-family: Lato-LightItalic, sans-serif;
}

@mixin LatoMedium {
    font-family: Lato-Medium, sans-serif;
}

@mixin LatoBold {
    font-family: Lato-Bold, sans-serif;
}

@mixin LatoBlack {
    font-family: Lato-Black, sans-serif;
}

@mixin LatoBlackItalic {
    font-family: Lato-BlackItalic, sans-serif;
}

@mixin LatoBoldItalic {
    font-family: Lato-BoldItalic, sans-serif;
}

@mixin LatoRegular {
    font-family: Lato-Regular, sans-serif;
}

@mixin LatoItalic {
    font-family: Lato-Italic, sans-serif;
}

@mixin LatoLight {
    font-family: Lato-Light, sans-serif;
}

@mixin font-style(
    $family: LatoRegular,
    $size: 1.4rem,
    $color: #000,
    $line-height: initial,
    $font-weight: normal
) {
    @if ($family == LatoRegular) {
        @include LatoRegular;
    } @else if($family == LatoBold) {
        @include LatoBold;
    } @else if($family == LatoBoldItalic) {
        @include LatoBoldItalic;
    } @else if($family == LatoBlack) {
        @include LatoBlack;
    } @else if($family == LatoBlackItalic) {
        @include LatoBlackItalic;
    } @else if($family == LatoMedium) {
        @include LatoMedium;
    } @else if($family == LatoItalic) {
        @include LatoItalic;
    } @else if($family == LatoLightItalic) {
        @include LatoLightItalic;
    } @else if($family == LatoLight) {
        @include LatoLight;
    } @else {
        font-family: $family;
    }
    font-size: $size;
    line-height: $line-height;
    font-weight: $font-weight;
    color: $color;
}
