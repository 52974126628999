@import 'variables';
@import 'mixins';

%menu-btn-style {
    @include border-radius(4px);
    @include font-style(LatoBold, 1rem, $border_btn_blue);
    display: inline-block;
    border: 2px solid $border_btn_blue;
    padding: 12px 18px;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
        background: $hover_btn_menu;
        color: $hover_btn_txt;
    }
}

%action-btn-style {
    @include border-radius(30px);
    @include font-style(LatoBold, 1.2rem, #fff);
    text-transform: uppercase;
    background: $almost_black;
    border: 2px solid $bg_warning;
    padding: 13px 36px;

    &:hover {
        background: $hover_btn_txt;
        border-color: $hover_btn_txt;
    }

    &.big-padding {
        padding: 13px 75px;
        font-size: 1.5rem;
    }
}

%input-style {
    @include border-radius(4px);
    @include font-style(LatoRegular, 1.4rem, $almost_black);
    width: 100%;
    padding: 12px 14px;
    border: 1px solid $footer;
    outline: 0;

    &:focus {
        outline: 3px solid $hover_btn_txt;
        border-color: transparent;
    }
}

%input-disabled-style {
    @include border-radius(4px);
    @include font-style(LatoBlackItalic, 1.4rem, $almost_black);
    background: none;
    border: 1px solid $green;
}

%default-btn-style {
    @include flex-centered-items;
    @include font-style(LatoBold, 1.2rem, #fff);
    @include border-radius(20px);
    position: relative;
    text-transform: uppercase;
    height: 40px;
    border: 2px solid $bg_warning;
    text-decoration: none;
    padding: 0 15px;
    background: none;
    white-space: nowrap;
}
