@import 'variables';
@import 'typography';
@import 'mixins';

.formset-wrapper {
    border: 4px solid $hover_btn_txt;
    max-width: 1420px;
    width: 100%;
    padding: 45px;

    h1,
    h2 {
        @include font-style(LatoBold, 1rem, $almost_black);
        display: inline-block;
        text-transform: uppercase;
        margin-bottom: 15px;

        &.form-header {
            @include flex-centered-items;
            max-width: 320px;
            margin: 0 auto 5px auto;
        }
    }
}

.form-container {
    @include flex-centered-items;
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    border: 2px solid #fff;
    background: $bg_grey_2;

    & + h2 {
        margin-top: 40px !important;
    }

    &.column {
        flex-direction: column;
    }

    &.wide {
        max-width: 847px;

        form {
            max-width: 847px;
        }
    }

    form {
        @include flex-simple-column;
        align-items: center;
        max-width: 330px;
        width: 100%;
        margin: 0 auto;
        padding: 15px;
        box-sizing: content-box;

        .main-action-btn {
            @include border-radius(4px);
            @include font-style(LatoBold, 1.5rem, #fff);
            background: $border_btn_blue;
            text-transform: uppercase;
            margin-top: 25px;
            padding: 12px 32px;
            border: 0;

            &:hover {
                background: $hover_btn_txt;
                cursor: pointer;
            }
        }

        .raw-button {
            @include font-style(LatoMedium, 1.4rem, $border_btn_blue);
            position: relative;
            text-decoration: underline;

            &:hover {
                text-decoration: none;

                &:before {
                    @include position-absolute($bottom: -3px, $left: 0);
                    content: '';
                    width: 100%;
                    height: 3px;
                    background: $border_btn_blue;
                }
            }
        }

        .secondary-action-btn {
            @include font-style(LatoBold, 1.5rem, #fff);
            padding: 12px 32px;
        }

        .form-buttons-container {
            margin-top: 30px;

            .main-action-btn {
                margin-top: 0;
            }
        }
    }

    .form-errors {
        @include flex-centered-items;
        @include font-style(LatoItalic, 1.4rem, #fff);
        @include border-radius(12px);
        width: 100%;
        background: $bg_warning;
        padding: 30px;

        .form-error-title {
            @include font-style(LatoBold, 1.8rem, $border_err_red);
            margin-right: 80px;
        }

        .errors {
            @include font-style(LatoRegular, 1.4rem, $almost_black);
        }

        @include media-layout('mobile') {
            @include flex-simple-column;
            align-items: flex-start;

            .form-error-title {
                margin: 0 0 10px 0;
            }
        }
    }
}

.dashed-btn-container {
    @include border-radius(12px);
    @include flex-full-centered-items;
    width: calc(100% - 30px);
    border: 4px dashed rgba($hover_btn_txt, 0.6);
    padding: 13px 0;
    margin: 15px 0;

    &.thin-border {
        border-width: 2px;
    }

    .btn-action {
        @extend %default-btn-style;
        color: $almost_black;
        border-color: $almost_black;

        &:hover {
            background: $footer;
            border-color: $footer;
        }
    }
}

form {
    h1 {
        @include font-style(LatoBold, 2rem, $almost_black, 2.6rem);
        margin-bottom: 20px;
        align-self: flex-start;
    }

    .form-fields {
        width: 100%;

        &.flex {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 1rem;

            .form-field {
                margin-bottom: 0;
            }
        }
    }

    .form-field {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        &.with-error {
            input {
                border: 3px solid $border_err_red;
            }
        }

        label {
            @include font-style(LatoBold, 1rem, $almost_black);
            display: block;
            margin-bottom: 4px;
            padding-left: 4px;
            text-transform: uppercase;
        }

        input {
            @extend %input-style;

            &[disabled] {
                @extend %input-disabled-style;
            }
        }

        .helptext {
            margin-top: 4px;
            @include font-style(LatoItalic, 1.2rem, rgba($almost_black, 0.5));
        }

        /* Customize the label (the container) */
        .radio-container {
            @include flex-full-centered-items;
            @include font-style(LatoRegular, 1.4rem, $almost_black);
            min-height: 30px;
            text-transform: initial;
            position: relative;
            padding-left: 35px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        /* Hide the browser's default radio button */
        .radio-container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom radio button */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 30px;
            width: 30px;
            background-color: #fff;
            border: 1px solid $footer;
            border-radius: 50%;
        }

        /* On mouse-over, add a grey background color */
        .radio-container:hover input ~ .checkmark {
            border: 2px solid $hover_btn_txt;
        }

        /* When the radio button is checked, add a blue background */
        .radio-container input:checked ~ .checkmark {
            background-color: #2196f3;
        }

        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
            content: '';
            position: absolute;
            display: none;
        }

        /* Show the indicator (dot/circle) when checked */
        .radio-container input:checked ~ .checkmark:after {
            display: block;
        }

        /* Style the indicator (dot/circle) */
        .radio-container .checkmark:after {
            top: 9px;
            left: 9px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: white;
        }

        .radio-container:hover .checkmark:after {
            top: 8px;
            left: 8px;
        }
    }

    p {
        @include font-style(LatoRegular, 1.4rem, $almost_black, 2.4rem);
        margin-bottom: 20px;
    }

    ul.errorlist {
        li {
            @include font-style(LatoBoldItalic, 1.2rem, $err_red);
            margin: 5px 0;
            text-align: end;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.btn-delete-form,
.btn-edit {
    @include flex-full-centered-items;
    @include border-radius(50%);
    width: 40px;
    height: 40px;
    background: $almost_black;
    color: #fff;
    border: 2px solid $bg_warning;
    flex-shrink: 0;
    text-decoration: none;

    &:hover {
        background: $hover_btn_txt;
    }

    span {
        font-size: 1.8rem;
    }
}

.table-filters {
    @extend form;
    @include flex-centered-items;
    position: relative;

    .form-fields {
        @include position-absolute($right: 15px, $top: 36px);
        flex-direction: column;
        padding: 15px;
        border: 2px solid #fff;
        background: $bg_grey_2;
        z-index: 2;
        width: 360px;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 0.3s ease;
    }

    .clear-btn {
        display: none;
        align-items: center;
        justify-content: center;
        @include font-style(LatoRegular, 1.2rem, $hover_btn_txt);
        text-transform: uppercase;
        background: none;
        border: none;
        height: 32px;
        margin-right: 15px;

        span {
            @include flex-full-centered-items;
            width: 25px;
            height: 25px;
            color: $hover_btn_txt;
            margin-right: 5px;

            &:hover {
                background: #fff;
            }
        }
    }
}

.captcha-container {
    @include flex-centered-items;

    .captcha-main {
        @include flex-centered-items;

        img {
            height: 80px;
            border: 1px solid $green;
            margin-left: 10px;
            margin-right: 4px;
        }

        .captcha-buttons {
            @include flex-simple-column;

            button {
                @include flex-full-centered-items;
                @include border-radius(2px);
                width: 38px;
                height: 38px;
                color: #fff;
                background: $border_btn_blue;
                border: none;

                &:first-child {
                    margin-bottom: 3px;
                }

                &:hover {
                    background: $green;
                    color: $almost_black;
                }
            }
        }
    }
}

.formset-container {
    width: 100%;
    padding: 15px;

    form {
        padding: 0;
        margin-bottom: 12px;
    }

    .flex-align-end-row,
    .flex-align-center-row {
        gap: 11px;
    }
}

.choices {
    display: flex;
    position: relative;
    font-size: 1.4rem;
    overflow: visible;
    height: 43px;
    min-width: 190px;

    &.is-focused {
        .choices__inner {
            border-color: $footer;
        }
    }

    .choices__inner {
        @extend %input-style;
        @include flex-centered-items;
        padding: 0 !important;
        font-size: 1.4rem;

        .choices__list {
            padding: 12px 14px;

            &.choices__list--single {
                display: flex;

                .choices__item {
                    @include flex-centered-items;
                    @include font-style(LatoRegular, 1.4rem, $almost_black);
                    gap: 1rem;
                    width: calc(100% - 5rem);

                    .choices__selected__text {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .choices__edit {
                        display: none;
                        padding: 0.4rem 2.5rem;
                        margin: 0 5rem 0 auto;
                        background-color: rgba($hover_btn_txt, 0.1);
                        border-radius: 30px;
                        border: none;
                        cursor: pointer;

                        &:hover {
                            background-color: $hover_btn_txt;
                            color: #ffffff;
                        }
                    }

                    & + .choices__placeholder {
                        display: none;
                    }
                }
            }
        }

        .choices__input {
            background-color: #ffffff;
        }
    }

    .choices__list--dropdown {
        margin-top: 1rem;

        &.is-active {
            outline: 3px solid $hover_btn_txt;
            border: none;
        }

        .choices__list {
            .choices__item {
                @include font-style(LatoRegular, 1.4rem, $almost_black);

                &.has-no-results,
                &.has-no-choices {
                    display: flex;
                    button {
                        @include font-style(LatoRegular, 1.4rem, $almost_black);
                        margin: auto;
                        transition: none;
                        padding: 0.5rem 3rem;
                    }
                }
            }
        }
    }

    &[data-type*='select-one'] {
        input.choices__input {
            border: none !important;

            &:focus {
                outline: none !important;
            }
        }

        &:after {
            content: '\f0d7' !important;
            @include flex-full-centered-items;
            @include position-absolute(
                $top: 4px !important,
                $right: 4px !important
            );
            @include border-radius(4px);
            font-family: 'Font Awesome 6 Free';
            font-weight: 900;
            width: 36px !important;
            height: 36px !important;
            margin: 0 !important;
            background: $border_btn_blue;
            border: 0;
            color: #fff;
        }
        &[aria-expanded='true']:after {
            content: '\f0d8' !important;
            top: 4px !important;
            right: 4px !important;
        }
    }

    &[data-type*='select-multiple'] {
        .choices__inner {
            height: auto;
            .choices__list {
                .choices__item {
                    background-color: $green;
                    border: 3px solid $bg_grey_2;
                    white-space: nowrap;

                    .choices__button {
                        border-left: none;
                        margin: 0;
                    }
                }
            }

            input.choices__input {
                border: none !important;

                &:focus {
                    outline: none !important;
                }
            }
        }
    }

    &.is-disabled {
        opacity: 0.5;
        .choices__inner {
            background-color: #fff;
            border: 1px solid #e1dfdf;
        }
    }

    &.is-open {
        .choices__inner {
            outline: 3px solid $hover_btn_txt;
            border: none;
        }
    }

    .choices__detail {
        @include font-style(LatoBoldItalic, 1.4rem, $almost_black);
        background-color: $hover_btn_txt;
        height: 2rem;
        width: 2rem;
        min-width: 2rem;
        border: none;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
    }

    .choices__input {
        border: none;
        outline: none;
        border-radius: 6px 6px 0 0 !important;
    }
}

.manual-form-footer {
    @include flex-space-between;
    @include border-radius(4px);
    position: fixed;
    left: 100px;
    bottom: 50px;
    width: calc(100% - 200px);
    border: 2px solid $bg_warning;
    padding: 30px 40px;
    z-index: 2;
    background: $almost_black;

    > div {
        @include flex-centered-items;
        gap: 15px;
    }

    .choices {
        width: 330px;
        margin-bottom: 0;

        &:after {
            border-color: $hover_btn_txt !important;
        }

        &.is-open {
            &:after {
                background: $green;
                border-color: $green !important;
                color: $almost_black;
            }
        }
    }

    a.disabled,
    button[disabled='disabled'],
    button:disabled {
        pointer-events: none;
    }

    .btn {
        @include flex-centered-items;
        @include font-style(LatoBold, 1.2rem, #fff);
        @include border-radius(20px);
        position: relative;
        text-transform: uppercase;
        height: 40px;
        border: 2px solid $bg_warning;
        text-decoration: none;
        padding: 0 15px;
        background: none;

        &:hover:not(.disabled) {
            background: $hover_btn_txt;

            .icon-bg {
                background: #fff;

                > span {
                    color: $almost_black;
                }
            }
        }

        &.disabled,
        &[disabled='disabled'],
        &:disabled {
            border-color: rgba($footer, 0.3);
            background: rgba($footer, 0.3);

            .icon-bg {
                border-color: rgba($footer, 0.3);
                background: rgba($footer, 0.3);
            }
        }

        .icon-bg {
            @include flex-full-centered-items;
            @include border-radius(50%);
            width: 40px;
            height: 40px;
            background: $hover_btn_txt;
            color: #fff;
            border: 2px solid $bg_warning;

            &.left {
                @include position-absolute($top: -2px, $left: -2px);
            }

            &.right {
                @include position-absolute($top: -2px, $right: -2px);

                > span {
                    left: 1px;
                }
            }

            > span {
                position: relative;
                font-size: 1.6rem;
                left: -1px;
            }
        }
    }

    .step-counter {
        @include font-style(LatoBold, 1.4rem, #fff);
    }

    #autosaveIndicator {
        margin-left: 5.5rem;
        display: flex;
        gap: 1rem;
        align-items: center;
        .autosave-icon {
            width: 20px;
            height: 20px;
            background: url(../images/autosaved.svg) center no-repeat
                transparent;

            &.saving {
                background: url(../images/autosaving.svg) center no-repeat
                    transparent;
            }
        }
        .autosave-label {
            @include font-style(LatoBold, 1.4rem, rgba($footer, 0.6));
        }
    }
}

.photo-layout-root {
    position: relative;
    padding: 15px 45px 80px 45px;
    border: 4px solid $hover_btn_txt;
    grid-row: 2;

    &.width-1420 {
        .manage-layout-container {
            width: 100%;
        }

        .slide-container {
            input {
                border: 3px solid $hover_btn_txt;
            }
        }

        .layout-select {
            z-index: 2;
        }

        .layout-photos {
            width: 100%;

            .layout {
                width: 100%;
                height: 635px;

                .slot {
                    overflow: hidden;
                }
            }
        }
    }

    &.fixed-height {
        height: 620px;
    }

    &.clear-root {
        position: relative;
        padding: 0;
        border: 0;

        .manage-layout-container {
            @include position-absolute($top: 20px, $left: 0);
            width: 100%;
            padding: 0 30px;
        }
    }

    .manage-layout-container {
        @include flex-align-end;
    }

    .layout-select {
        @include border-radius(36px);
        display: grid;
        gap: 2.6rem;
        background: #fff;
        border: 4px solid $footer;
        padding: 10rem;

        &.hidden {
            display: none;
        }

        &.hovered {
            @include position-absolute($top: 90px, $left: 50px);
            display: grid;
        }

        &.width-1200-centered {
            &:not(.hidden),
            &.hovered {
                display: inline-grid;
                max-width: 1200px;
                width: 100%;
                margin: 0 auto;
                padding-left: 165px;
                padding-right: 165px;

                .layout {
                    width: 180px;
                    height: 80px;
                }
            }

            &.hovered {
                left: 110px;
            }
        }

        &.square {
            grid-template-columns: 1fr 1fr 1fr;
        }

        &.rectangle {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        .layout {
            position: relative;
            width: 154px;
            height: 120px;
            cursor: pointer;
            box-shadow: none;
            transition: box-shadow 0.3s ease-out;

            &:hover {
                box-shadow:
                    0 0 0 5px #fff,
                    0 0 0 15px $hover_btn_txt;
            }

            .slot {
                position: relative;
            }
        }
    }

    .layout-photos {
        display: grid;
        gap: 2.6rem;

        &.hidden {
            display: none;
        }

        .layout {
            width: 800px;
            height: 620px;

            .slot {
                min-height: 0;
                min-width: 0;
                img {
                    cursor: pointer;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    &.hidden {
                        display: none;
                    }
                }

                .fileupload-container {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;

                    &.hidden {
                        display: none;
                    }

                    input {
                        display: none;
                    }

                    .fileupload-center {
                        height: 100%;
                        background: url(../images/upload.svg) center no-repeat;

                        &:hover {
                            background: url(../images/upload_hover.svg) center
                                no-repeat;
                        }
                    }
                }
            }
        }
    }

    .layout {
        display: grid;
        grid-gap: 4px;
        background-color: $hover_btn_txt;
        border: 4px solid $hover_btn_txt;

        .slot {
            background-color: #ffffff;
        }

        &.sq_1 {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            .slot:not(:nth-child(-n + 1)) {
                display: none;
            }
        }

        &.sq_2 {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, calc(50% - 2px));

            .slot:not(:nth-child(-n + 2)) {
                display: none;
            }
        }

        &.sq_3 {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;

            .slot:not(:nth-child(-n + 2)) {
                display: none;
            }
        }

        &.sq_4 {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }

        &.sq_5 {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(2, calc(50% - 2px));

            .slot:not(:nth-child(-n + 3)) {
                display: none;
            }

            .slot:nth-child(3) {
                grid-column: 1 / 3;
            }
        }

        &.sq_6 {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(2, calc(50% - 2px));

            .slot:not(:nth-child(-n + 3)) {
                display: none;
            }

            .slot:nth-child(1) {
                grid-column: 1 / 3;
            }
        }

        &.rect_1 {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            .slot:not(:nth-child(-n + 1)) {
                display: none;
            }
        }

        &.rect_2 {
            grid-template-columns: 2fr 1fr;
            grid-template-rows: 1fr;

            .slot:not(:nth-child(-n + 2)) {
                display: none;
            }
        }

        &.rect_3 {
            grid-template-columns: 1fr 2fr;
            grid-template-rows: 1fr;

            .slot:not(:nth-child(-n + 2)) {
                display: none;
            }
        }

        &.rect_4 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr;

            .slot:not(:nth-child(-n + 3)) {
                display: none;
            }
        }

        &.rect_5 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            .slot:not(:nth-child(-n + 4)) {
                display: none;
            }

            .slot:nth-child(2) {
                grid-area: 1 /2 /3 /3;
            }

            .slot:nth-child(3) {
                grid-area: 1 /3 /3 /4;
            }
        }

        &.rect_6 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            .slot:not(:nth-child(-n + 5)) {
                display: none;
            }

            .slot:nth-child(3) {
                grid-area: 1 /3 /3 /4;
            }
        }

        &.rect_7 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            .slot:not(:nth-child(-n + 5)) {
                display: none;
            }

            .slot:nth-child(2) {
                grid-area: 1 /2 /3 /3;
            }
        }

        &.rect_8 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            .slot:not(:nth-child(-n + 5)) {
                display: none;
            }

            .slot:nth-child(1) {
                grid-area: 1 /1 /3 /2;
            }
        }

        &.rect_9 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            .slot:not(:nth-child(-n + 4)) {
                display: none;
            }

            .slot:nth-child(1) {
                grid-area: 1 /1 /3 /2;
            }

            .slot:nth-child(2) {
                grid-area: 1 /2 /3 /3;
            }
        }

        &.rect_10 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            .slot:not(:nth-child(-n + 3)) {
                display: none;
            }

            .slot:nth-child(2) {
                grid-area: 1 /2 /3 /4;
            }
        }

        &.rect_11 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            .slot:not(:nth-child(-n + 3)) {
                display: none;
            }

            .slot:nth-child(1) {
                grid-area: 1 /1 /3 /3;
            }
        }

        &.rect_12 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }

        &.rect_13 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            .slot:not(:nth-child(-n + 4)) {
                display: none;
            }

            .slot:nth-child(1) {
                grid-area: 1 /1 /2 /3;
            }

            .slot:nth-child(2) {
                grid-area: 1 /3 /3 /4;
            }
        }

        &.rect_14 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            .slot:not(:nth-child(-n + 4)) {
                display: none;
            }

            .slot:nth-child(3) {
                grid-area: 1 /3 /3 /4;
            }

            .slot:nth-child(4) {
                grid-area: 2 /1 /3 /3;
            }
        }

        &.rect_15 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            .slot:not(:nth-child(-n + 4)) {
                display: none;
            }

            .slot:nth-child(1) {
                grid-area: 1 /1 /3 /2;
            }

            .slot:nth-child(2) {
                grid-area: 1 /2 /2 /4;
            }
        }

        &.rect_16 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            .slot:not(:nth-child(-n + 4)) {
                display: none;
            }

            .slot:nth-child(1) {
                grid-area: 1 /1 /3 /2;
            }

            .slot:nth-child(4) {
                grid-area: 2 /2 /3 /4;
            }
        }
    }
}

.banner-form {
    display: flex;
    width: 100%;
    padding-right: 22px;

    form {
        display: none;
        width: 100%;
        padding-left: 22px;
        padding-right: 22px;

        .form-fields {
            display: flex;

            .form-field {
                @include flex-full-centered-items;
                margin-bottom: 0;
                margin-right: 22px;

                input[type='text'] {
                    width: 800px;
                }

                & div {
                    display: flex;

                    > div {
                        margin-right: 30px;
                    }
                }
            }
        }
    }
    .remove-banner {
        display: none;
    }
    &.active {
        form {
            display: flex;
        }
        .remove-banner {
            display: inline-block;
        }
        .add-banner {
            display: none;
        }
    }
}

.workspace-with-aside {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 40px;

    .formset-wrapper {
        flex-shrink: 0;
    }
}

.manual-aside-panel {
    @include flex-full-centered-items;
    @include border-radius(34px);
    min-width: 320px;
    background: rgba(#fff, 0.5);

    .btn-action {
        @extend %default-btn-style;
        justify-content: center;
        width: 230px;
        height: 60px;
        text-align: center;
        color: $almost_black;
        border-color: $almost_black;

        &:hover {
            background: $footer;
            border-color: $footer;
        }
    }
}

.step-index {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .step {
        @include flex-space-between;
        @include border-radius(4px);
        background-color: #ffffff;
        border: 2px solid $bg_grey_2;
        height: 60px;
        padding-left: 32px;
        padding-right: 14px;

        &.draggable {
            border-color: $green;
            padding-left: 14px;
        }

        > div:first-child {
            @include flex-centered-items;

            .ordering-handle {
                bottom: 0;
                margin-right: 25px;
            }
        }

        b {
            @include font-style(LatoBold, 1.4rem, $almost_black);
            margin-right: 17px;
        }

        p {
            @include font-style(LatoRegular, 1.4rem, $almost_black);
        }

        .step-actions {
            @include flex-centered-items;

            .btn-delete-form {
                margin-right: 10px;
            }
        }
    }
}

.ordering-handle {
    @include border-radius(2px);
    @include flex-full-centered-items;
    min-width: 64px;
    height: 32px;
    cursor: grab;
    background-color: #e5ecad;
    border: 2px solid #fff;

    &:hover {
        background: $border_btn_blue;

        > span {
            color: #fff;
        }
    }

    > span {
        font-size: 1.4rem;
    }
}

.grid-with-placeholder {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 84px 1fr;
    grid-row-gap: 35px;

    .table-placeholder-1 {
        grid-column: 1 / 3;
        background: url(../images/table_placeholder_1.svg) center no-repeat;
    }

    .table-placeholder-2 {
        grid-row: 2;
        background: url(../images/table_placeholder_2.svg) top right no-repeat;
    }
}
