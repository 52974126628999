@import 'variables';
@import 'mixins';

$icomoon-font-family: 'reelectro' !default;
$icomoon-font-path: '../fonts' !default;

$icon-volume: unquote('"\\e910"');
$icon-refresh: unquote('"\\e911"');
$icon-move: unquote('"\\e912"');
$icon-hamburger: unquote('"\\e90f"');
$icon-dots: unquote('"\\e90e"');
$icon-filter: unquote('"\\e90d"');
$icon-arrow_right: unquote('"\\e900"');
$icon-lock: unquote('"\\e901"');
$icon-bin: unquote('"\\e902"');
$icon-arrow_down: unquote('"\\e903"');
$icon-tick: unquote('"\\e904"');
$icon-close-bold: unquote('"\\e905"');
$icon-profile: unquote('"\\e906"');
$icon-tick_circle: unquote('"\\e907"');
$icon-attachment: unquote('"\\e908"');
$icon-comment: unquote('"\\e909"');
$icon-edit: unquote('"\\e90a"');
$icon-close-thin: unquote('"\\e90b"');
$icon-pen_to_square: unquote('"\\e90c"');

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?27cttm');
    src:
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?27cttm#iefix')
            format('embedded-opentype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?27cttm')
            format('truetype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?27cttm')
            format('woff'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?27cttm##{$icomoon-font-family}')
            format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-volume {
    &:before {
        content: $icon-volume;
    }
}
.icon-refresh {
    &:before {
        content: $icon-refresh;
    }
}
.icon-hamburger {
    &:before {
        content: $icon-hamburger;
    }
}
.icon-dots {
    &:before {
        content: $icon-dots;
    }
}
.icon-filter {
    &:before {
        content: $icon-filter;
    }
}
.icon-arrow_right {
    &:before {
        content: $icon-arrow_right;
    }
}
.icon-lock {
    &:before {
        content: $icon-lock;
    }
}
.icon-bin {
    &:before {
        content: $icon-bin;
    }
}
.icon-arrow_down {
    &:before {
        content: $icon-arrow_down;
    }
}
.icon-tick {
    &:before {
        content: $icon-tick;
    }
}
.icon-close-bold {
    &:before {
        content: $icon-close-bold;
    }
}
.icon-profile {
    &:before {
        content: $icon-profile;
    }
}
.icon-tick_circle {
    &:before {
        content: $icon-tick_circle;
    }
}
.icon-attachment {
    &:before {
        content: $icon-attachment;
    }
}
.icon-comment {
    &:before {
        content: $icon-comment;
    }
}
.icon-edit {
    &:before {
        content: $icon-edit;
    }
}
.icon-close-thin {
    &:before {
        content: $icon-close-thin;
    }
}
.icon-pen_to_square {
    &:before {
        content: $icon-pen_to_square;
    }
}
.icon-move {
    &:before {
        content: $icon-move;
    }
}
.bg-icon {
    @include flex-full-centered-items;
    @include border-radius(50%);
    color: #fff;

    &.profile {
        position: relative;
        left: 2px;
        width: 32px;
        height: 32px;
        font-size: 1.4rem;
        background: $border_btn_blue;
        border: 2px solid #fff;
    }

    &.filter {
        @include border-radius(4px);
        width: 32px;
        height: 32px;
        background: $border_btn_blue;
    }
}
