@import 'mixins';
@import 'variables';
@import 'typography';

.error-box {
    @include flex-centered-column;
    @include border-radius(68px);
    max-width: 430px;
    max-height: 430px;
    width: 100%;
    height: 100%;
    background: $green;
    padding: 104px;
    margin: 0 auto;

    @include media-layout('small') {
        max-height: initial;
    }

    @include media-layout('mobile') {
        padding: 30px;
    }

    h1 {
        @include font-style(LatoBlack, 12rem, $light_green, 10rem);
        margin-bottom: 15px;
    }

    h2 {
        @include font-style(LatoBold, 1.6rem, #fff);
        margin-bottom: 8px;
    }

    p {
        @include font-style(LatoItalic, 1.6rem, $almost_black);
        text-align: center;
        margin-bottom: 20px;
    }

    h3 {
        @include font-style(LatoBold, 1.4rem, $light_green);
        margin-bottom: 5px;
    }

    a {
        @include font-style(LatoItalic, 1.4rem, $almost_black);
    }
}
