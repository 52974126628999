/* COLORS */
$bg_grey: #f2f2ef;
$border_btn_blue: #1b9ad6;
$hover_btn_menu: #d4f5fe;
$hover_btn_txt: #2068e3;
$footer: #7be4ff;
$almost_black: #201b21;
$light_green: #f2f4d7;
$bg_err: #ff7c78;
$border_err_red: #e34f20;
$err_red: #d40000;
$green: #bdd130;
$bg_warning: #f6ff7a;
$green_2: #b7cb20;
$bg_success: #00ff81;
$bg_grey_2: #e9e8e3;
$almost_white: #f6f6f4;

/* BREAKPOINTS FOR RESPONSIVENESS */
$breakpoints: (
    'medium': 1366px,
    'small': 768px,
    'mobile': 390px,
);
