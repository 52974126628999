@import 'reset';
@import '_variables';
@import '_mixins';
@import '_bases';
@import 'forms';
@import 'alerts';
@import 'tabs';
@import 'icons';
@import 'errors';
@import 'buttons';
@import 'modals';
@import 'disassembly';

html {
    height: 100%;
    font-size: 10px;
}

body {
    position: relative;
    display: block;
    height: 100%;
    background: $bg_grey;
}

.page-container {
    @include flex-simple-column;
    min-height: 100%;

    #content {
        @include flex-simple-column;
        flex-grow: 1;
        min-height: 100%;
        padding: 100px 5px 200px 5px;

        @include media-layout('small') {
            padding-top: 100px;
        }
    }

    footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;

        .bg-footer {
            width: 100%;
            height: 140px;
            background: $bg_grey_2;
            border-top: 1px solid $bg_warning;
        }

        p {
            @include flex-full-centered-items;
            @include font-style(LatoRegular, 1rem, #fff);
            width: 100%;
            height: 30px;
            background: $footer;
        }
    }
}

#top-header {
    @include flex-space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: #fff;
    padding: 0 30px 0 23px;
    z-index: 999;

    @include media-layout('small') {
        height: 50px;
        padding: 0 10px 0 4px;
    }

    #logo-link {
        img {
            height: 59px;

            @include media-layout('small') {
                height: 40px;
            }
        }
    }

    .info-banner {
        flex-grow: 1;
        display: flex;
        align-items: center;
        height: 100%;

        .editor-mode {
            @include font-style(LatoBold, 1.6rem, $almost_black);
            padding: 0 3.2rem;
            height: 100%;
            align-self: flex-start;
            margin: auto 0 auto 1.5rem;
            background-color: #cef5ff;
            display: flex;
            align-items: center;
        }

        .bhp {
            @include font-style(LatoRegular, 1.6rem, $almost_black);
            background-color: $bg_warning;
            border: 2px solid #ffffff;
            padding: 2rem 6.4rem;
            border-radius: 4px;
            margin: auto;

            strong {
                @include font-style(LatoBold, 1.6rem, $almost_black);
                margin-right: 0.5rem;
            }
        }

        .preview-link {
            box-shadow: none;
            margin: auto 0 auto 3.2rem;
            background-color: $hover_btn_txt;
            border-color: $bg_warning;

            &:hover {
                background-color: $green_2;
            }
        }
    }

    nav#main-nav {
        ul {
            @include flex-centered-items;

            li {
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }

                a.menu-btn {
                    @extend %menu-btn-style;
                }

                a.profile-link {
                    @include border-radius(20px);
                    @include flex-centered-items;
                    height: 40px;
                    background: $light_green;
                    text-decoration: none;
                    border: 3px solid transparent;

                    &:hover {
                        border-color: $green;
                    }

                    .profile-username {
                        @include flex-simple-column;
                        padding: 8px 30px 8px 11px;

                        span:first-child {
                            @include font-style(
                                LatoRegular,
                                1rem,
                                $almost_black
                            );
                        }

                        span {
                            @include font-style(LatoBold, 1rem, $almost_black);
                        }
                    }
                }
            }
        }

        @include media-layout('small') {
            @include position-absolute($top: 55px, $right: 0);
            display: none;

            &.opened {
                display: block;
                z-index: 999;
            }

            ul {
                @include border-radius(12px);
                @include flex-simple-column;
                background: #fff;
                padding: 90px;

                li {
                    margin: 0;

                    &.profile {
                        margin-bottom: 45px;
                    }
                }

                @include media-layout('mobile') {
                    width: 100vw;
                }
            }
        }
    }
}

.flex-space-around {
    @include flex-space-around;
    width: 100%;
}

.flex-end {
    align-self: flex-end;
}

.flex-grow {
    flex-grow: 1;
}

.big-box {
    @include flex-full-centered-items;
    max-width: 800px;
    width: 100%;
    max-height: 574px;
    height: 100%;
    flex-grow: 1;
    padding: 40px 22px 40px 22px;

    p {
        text-align: center;
        @include font-style(LatoRegular, 2rem, $almost_black);

        strong {
            @include font-style(LatoBold, 2rem, $almost_black);
        }
    }
}

.wide-container {
    @include border-radius(12px);
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    background: $bg_grey;

    h1 {
        @include font-style(LatoBold, 2rem, $almost_black);
    }
}

.width-1420 {
    max-width: 1420px;
    width: 100%;
}

.width-730 {
    max-width: 730px;
    width: 100%;
}

.bordered-container {
    @include border-radius(12px);
    padding: 15px;
    border: 2px solid #fff;
}

button {
    &:hover {
        cursor: pointer;
    }
}

.vertical-center {
    justify-content: center;
}

#mobile-menu-btn {
    @include flex-full-centered-items;
    @include border-radius(4px);
    display: none;
    width: 40px;
    height: 40px;
    border: 2px solid $border_btn_blue;
    background: none;
    color: $border_btn_blue;

    &:hover {
        background: $hover_btn_txt;
        border-color: $bg_warning;
        color: #fff;
    }

    @include media-layout('small') {
        display: initial;
    }
}

.flex {
    display: flex;
}

.flex-align-end {
    @include flex-align-end;
}

.flex-align-end-row {
    align-items: flex-end !important;
    flex-direction: row !important;
}

.flex-align-center-row {
    align-items: center !important;
    flex-direction: row !important;
}

.btn-action {
    @extend %action-btn-style;
}

.flex-center-column {
    @include flex-centered-column;
}

.flex-center-row {
    @include flex-full-centered-items;
    flex-direction: row !important;
}

.column {
    flex-direction: column !important;
}

.rotate-180 {
    transform: rotate(180deg);
}

.clock {
    @include flex-full-centered-items;
    @include font-style(LatoBold, 2rem, #fff);
    @include border-radius(20px);
    height: 40px;
    padding: 0 22px;
    background: $border_btn_blue;
}

.center {
    margin: 0 auto;
}

.hidden {
    display: none;
}
