@import 'variables';

.disassembly-slide-container {
    display: flex;
    gap: 4rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 5rem;
    height: calc(100vh - 300px);

    .slide-contents {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .photo-layout-root {
            border: 1px solid $footer;
            background-color: #ffffff;
            padding: 0;

            .layout-photos {
                .layout {
                    background-color: $footer;
                    border: none;
                    grid-gap: 1px;

                    .slot {
                        background-color: #ffffff;
                        padding: 4px;
                        img {
                            cursor: auto;
                        }
                    }
                }
            }
        }

        .banner {
            @include font-style(LatoBold, 2rem, #ffffff);
            width: 100%;
            max-width: 1420px;
            text-transform: uppercase;
            border: 1px solid $footer;
            padding: 1.8rem 0;
            text-align: center;

            &.I {
                background-color: $hover_btn_txt;
            }

            &.W {
                background-color: $border_err_red;
                border-color: #ffffff;
            }

            &.A {
                background-color: $bg_warning;
                border-color: #ffffff;
                color: $almost_black;
            }
        }

        .banner-placeholder {
            width: 100%;
            max-width: 1420px;
            padding: 3.1rem 0;
        }
    }

    .actions-contents {
        flex-grow: 1;

        .disassembly-wrapper {
            margin-top: 0;
        }
    }
}

#disassemblySidePanel {
    width: 370px;
    border: 4px solid #e5ecad;
    border-radius: 34px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 1.8rem 1.5rem;
    height: 100%;

    .header {
        @include font-style(LatoBold, 1.6rem, $almost_black);
        margin-bottom: 3rem;
    }

    .disassembly-parts {
        display: flex;
        flex-direction: column;
        gap: 2.1rem;
        padding-bottom: 1rem;
        border-bottom: 3px solid rgba($bg-grey_2, 0.3);

        width: 100%;
        height: 100%;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            width: 0 !important;
        }

        .disassembly-part {
            width: 100%;
            cursor: pointer;

            small {
                display: flex;
                justify-content: space-between;
                .counter {
                    @include font-style(LatoBold, 1rem, $almost_black);
                }

                .status {
                    @include font-style(LatoRegular, 1rem, $almost_black);
                    text-transform: uppercase;
                }
            }

            .part-input {
                @include font-style(LatoBold, 1.4rem, $almost_black);
                background-color: $bg_grey;
                position: relative;
                border: 2px solid $bg_grey;
                padding: 1.3rem;
                display: flex;
                align-items: center;
                user-select: none;

                .disassembly-checkbox {
                    width: 30px;
                    height: 30px;
                    margin: 0 1.8rem 0 0;
                    border: 2px solid $hover_btn_txt;
                    pointer-events: none;
                    border-radius: 4px;
                    appearance: none;
                    -webkit-appearance: none;
                    background: url(../images/checkmark.svg) center no-repeat
                        #ffffff;
                    background-size: 14px;

                    &:checked {
                        background-color: $hover-btn_txt;
                    }
                }

                .right-side {
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;

                    .part-comments-btn {
                        background: url(../images/comments.svg) center no-repeat;
                        border: 2px solid #ffffff;
                        border-radius: 50%;
                        background-color: $footer;
                        outline: none;
                        height: 27px;
                        width: 27px;
                        margin-top: 1px;
                        overflow: visible;

                        .comments-wrapper {
                            display: none;
                            position: absolute;
                            z-index: 10;
                            left: 0;
                            right: 0;
                            top: 40px;
                            background-color: $light-green;
                            border: 3px solid #ffffff;
                            padding: 0.9rem;
                            border-radius: 9px;
                            box-shadow: 0 3px 6px #185acb30;

                            .part-comments {
                                width: 100%;
                                padding: 1.2rem;
                                resize: none;
                                outline: none;
                                border: 1px solid $footer;
                                border-radius: 4px;
                                height: 18rem;

                                &::placeholder {
                                    @include font-style(
                                        LatoBlackItalic,
                                        1.4rem,
                                        rgba($almost_black, 0.3)
                                    );
                                }

                                &:focus {
                                    border: 3px solid $hover-btn_txt;
                                }
                            }
                        }

                        &:focus,
                        &:focus-within,
                        &:hover {
                            background-color: $hover_btn_txt;
                            border-color: $hover_btn_txt;
                            outline: 6px solid rgba($hover_btn_txt, 0.12);

                            .comments-wrapper {
                                display: block;
                            }
                        }
                    }

                    .status-icon {
                        height: 27px;
                        width: 27px;
                    }
                }
            }

            &.N {
                small .status {
                    @include font-style(LatoBoldItalic, 1rem, $almost_black);
                    opacity: 0.5;
                }

                &:hover {
                    .status-icon {
                        background: $bg-grey_2;
                        border: 2px solid #ffffff;
                        border-radius: 50%;
                    }
                }
            }

            &.D {
                .part-input {
                    border-color: $green_2;
                }

                .status-icon {
                    background: url(../images/status-ok.svg) center no-repeat;
                }
            }

            &.C {
                .part-input {
                    border-color: #f2ce1c;
                    background-color: #f2ce1c0d;
                }

                .status-icon {
                    background: url(../images/status-warning.svg) center
                        no-repeat;
                }
            }

            &.G {
                small {
                    .status,
                    .counter {
                        @include font-style(LatoBold, 1rem, $border_err_red);
                    }
                }

                .part-input {
                    border-color: $border_err_red;
                    background-color: #e34f200d;
                }

                .status-icon {
                    background: url(../images/status-danger.svg) center
                        no-repeat;
                }
            }
        }
    }

    .side-panel-footer {
        margin-top: auto;
        width: 100%;

        .device-card {
            @include font-style(LatoBold, 1.4rem, $hover_btn_txt);
            background-color: rgba($bg-grey, 0.3);
            width: 100%;
            height: 6rem;
            border-radius: 0 0 22px 22px;
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            gap: 1rem;
            justify-content: center;

            .icon-card {
                display: inline-block;
                height: 27px;
                width: 27px;
                background: url(../images/document.svg) center no-repeat;
                background-size: 27px 27px;
            }

            &:hover {
                background-color: #cef5ff;
            }
        }
    }
}

.disassembly-wrapper {
    border: 1px solid $footer;
    max-width: 1420px;
    width: 100%;
    min-height: calc(100vh - 510px);
    padding: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

    &.red {
        border-color: $border_err_red;
    }

    h1,
    h2 {
        @include font-style(LatoBold, 1rem, $almost_black);
        display: inline-block;
        text-transform: uppercase;
        margin-bottom: 5px;
    }
}

.disassembly-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 730px;

    .disassembly-box {
        @include font-style(LatoRegular, 1.4rem, $almost_black);
        padding: 0.6rem 0.6rem 0.6rem 2.2rem;
        border-radius: 4px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;

        .disassembly-action {
            @include font-style(LatoBold, 1.4rem, $border_btn_blue);
            width: 179px;
            padding: 0.5rem 0;
            border-radius: 4px;
            background-color: #ffffff;
            border: 2px solid $border_btn_blue;

            &.confirmed {
                color: $almost_black;
                background-color: $green;
                border: 2px solid $green;
            }

            &:hover {
                color: $hover_btn_txt;
                background-color: $hover_btn_menu;
                border-color: $hover_btn_txt;
            }
        }

        .placeholder {
            width: 179px;
            height: 100%;
            border-radius: 4px;
            background-color: $bg_grey;
        }
    }
}

.disassembly-footer {
    @include flex-space-between;
    position: fixed;
    bottom: 30px;
    width: 100%;
    height: 139px;
    padding: 30px 40px;
    z-index: 2;
    background-color: #ffffff;

    &.blue {
        background: $hover_btn_txt;
    }

    .scan-device {
        @include font-style(LatoBold, 1.5rem, $hover_btn_txt);
        border-radius: 4px;
        border: 2px solid #ffffff;
        background-color: $bg-grey;
        padding: 2.7rem 8.7rem;
        text-decoration: none;
        justify-self: center;
        margin: auto;

        &:hover {
            background-color: $hover_btn_menu;
            border-color: $hover_btn_txt;
        }
    }

    .btn-placeholder {
        border-radius: 3.4rem;
        border: 3px solid #ffffff;
        padding: 5.5rem 18.5rem;
        background-color: $light_green;
        opacity: 0.5;
    }

    .btn-next {
        @include font-style(LatoBold, 2rem, $almost_black);
        background-color: $green_2;
        width: 370px;
        padding: 0 32px;
        height: 110px;
        border-radius: 3.4rem;
        border: 3px solid #ffffff;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        &:hover {
            background-color: $hover_btn_txt;
            color: #ffffff;
        }

        span {
            margin-left: auto;
        }

        .icon-arrow_right {
            font-size: 64px;
        }

        &.blue {
            border-color: $border_btn_blue;
            background-color: #ffffff;
            color: $almost_black;

            &:hover {
                background-color: $hover_btn_txt;
                border-color: #ffffff;
                color: #ffffff;
            }
        }
    }

    .disassembly-comments {
        background-color: $light-green;
        width: 370px;
        height: 110px;
        border-radius: 3.4rem;
        border: 3px solid #ffffff;
        padding: 2rem;
        margin-bottom: 0;
        transition:
            margin-bottom 150ms,
            height 150ms;

        &:not(.placeholder) {
            &:focus-within,
            &:hover {
                height: 260px;
                margin-bottom: 150px;

                textarea {
                    height: 180px;
                    border: 3px solid $hover_btn_txt;
                    outline: none;
                }
            }
        }

        label {
            @include font-style(LatoBold, 1rem, $almost_black);
        }

        textarea {
            width: 330px;
            border: 1px solid $footer;
            border-radius: 4px;
            height: 44px;
            resize: none;
            padding: 1.2rem;
            transition:
                height 150ms,
                border-width 150ms,
                border-color 150ms;

            &::placeholder {
                @include font-style(
                    LatoBlackItalic,
                    1.4rem,
                    rgba($almost_black, 0.3)
                );
            }
        }
    }

    .btn-cancel {
        @include font-style(LatoBold, 1.5rem, $hover_btn_txt);
        background-color: $bg-grey;
        border: 2px solid $hover_btn_txt;
        border-radius: 4px;
        padding: 1.7rem 6.2rem;
        text-align: center;
        text-decoration: none;

        &:hover {
            background-color: $hover_btn_menu;
        }
    }

    .slide-navigation {
        display: flex;
        align-items: center;
        gap: -1rem;
        a {
            border: 3px solid #e5ecad;
            padding: 2.4rem 6.2rem;

            &.left {
                border-radius: 34px 0px 0px 34px;
                margin-right: -4.4rem;
                padding-right: 8.4rem;

                &:hover {
                    background-color: $footer;
                    border-color: #ffffff;

                    .arrow-left {
                        filter: brightness(0) invert(1);
                    }
                }

                .arrow-left {
                    background: url(../images/arrow-left.svg) center no-repeat;
                }
            }

            &.right {
                border-radius: 0px 34px 34px 0px;
                margin-left: -4.4rem;
                padding-left: 8.4rem;

                &:hover {
                    background-color: $hover_btn_txt;
                    border-color: #ffffff;
                    .arrow-right {
                        filter: brightness(0) invert(1);
                    }
                }

                .arrow-right {
                    background: url(../images/arrow-right.svg) center no-repeat;
                }
            }

            span {
                display: block;
                width: 38px;
                height: 62px;
            }
        }

        .slide-number {
            @include font-style(LatoBlackItalic, 2rem, $border_btn_blue);
            border: 3px solid #e5ecad;
            border-radius: 50%;
            padding: 2.5rem;
            z-index: 2;
            background-color: #ffffff;
        }
    }
}

.actions-banner {
    @include font-style(LatoBoldItalic, 2rem, #ffffff);
    background-color: $green_2;
    border: 1px solid $footer;
    width: 100%;
    max-width: 1420px;
    margin-top: 1rem;
    padding: 1.7rem 0;
    text-align: center;

    &.red {
        background-color: $border_err_red;
        border-color: #ffffff;
    }
}

.device-identification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    p {
        @include font-style(LatoBold, 1rem, $almost_black);
        margin-right: 55rem;
    }

    .device-id {
        @include font-style(LatoRegular, 2rem, $almost_black);
        background-color: #ffffff;
        padding: 2rem;
        border-radius: 4px;
        width: 730px;
        b {
            @include font-style(LatoBold, rem, $almost_black);
        }
    }
}

.specification-container {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    max-width: 1600px;
    margin: 3.5rem auto 0 auto;
    background-color: #ffffff;

    .table-wrapper {
        @include font-style(LatoRegular, 1.2rem, $almost_black);
        padding: 2.4rem 1.9rem;
        border: 1px solid $footer;

        .specification-table {
            width: 100%;
            th {
                text-align: left;
                padding: 1rem 0 1rem 2rem;
            }
            td {
                text-align: left;
                padding: 1rem 2rem 1rem 0;
            }
            tr:nth-child(even) {
                background-color: rgba($bg-grey, 0.9);
            }
        }
    }

    .photo-layout-root {
        grid-column: 2;
        grid-row: 1;
        padding: 2.4rem 1.9rem;
        border: 1px solid $footer;

        .layout-photos {
            .layout {
                border: none;
                background-color: #ffffff;
                width: 762px;
                height: 572px;

                .slot {
                    background-color: #ffffff;
                    img {
                        cursor: auto;
                    }
                }
            }
        }
    }
}

.finished-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 0;
    h1 {
        @include font-style(LatoBold, 2rem, $almost_black);
        text-transform: none;
    }

    .finished-icon-container {
        background-color: #ffffff;
        border-radius: 68px;
        padding: 3.5rem 16.5rem;
        .finished-icon {
            display: block;
            border-radius: 50%;
            background: url(../images/checkmark.svg) center no-repeat $green;
            width: 14rem;
            height: 14rem;
        }
    }
}

.preview {
    border: 12px solid $almost_black;
    position: absolute;
    z-index: 20000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    .accent-border {
        width: 100%;
        height: 100%;
        border: 2px solid $bg_warning;

        .preview-text {
            @include font-style(LatoBold, 20rem, rgba($almost_black, 0.05));
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 100%;
            width: 100%;
            transform: matrix(0.94, 0.34, -0.34, 0.94, 0, 0);
        }

        .page-container {
            #top-header {
                top: 14px;
                left: 14px;
                right: 14px;
                width: auto;
            }

            footer {
                left: 14px;
                right: 14px;
                bottom: 14px;
                width: auto;
            }

            .disassembly-footer {
                left: 14px;
                right: 14px;
                bottom: 44px;
                width: auto;
            }

            .photo-layout-root {
                position: inherit;
            }
        }
    }

    .preview-blocker {
        position: absolute;
        top: 70px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
    }
}
