@import 'variables';
@import 'typography';
@import 'mixins';

.swal2-modal {
    @include border-radius(12px);

    .swal2-title {
        @include font-style(LatoRegular, 2.5rem, $almost_black);
        text-transform: uppercase;
        margin-bottom: 25px;
    }

    .swal2-html-container {
        @include font-style(LatoRegular, 2rem, $almost_black);
        @include border-radius(12px);
        background: #fff;
        padding: 60px 56px;
    }

    .swal2-actions {
        @include flex-align-end;
        gap: 20px;
        width: 100%;
        margin-top: 35px;

        .btn-submit {
            @include flex-full-centered-items;
            @include font-style(LatoBold, 1.5rem, #fff);
            @include border-radius(4px);
            background: $border_btn_blue;
            height: 48px;
            padding: 0 60px;
            border: 0;
            text-transform: uppercase;

            &:hover {
                background: $hover_btn_txt;
            }
        }

        .btn-secondary {
            @include flex-full-centered-items;
            @include font-style(LatoBold, 1.5rem, $hover_btn_txt);
            @include border-radius(4px);
            background: none;
            height: 48px;
            padding: 0 36px;
            border: 0;
            text-transform: uppercase;

            &:hover {
                background: $hover_btn_menu;
            }
        }
    }
}
