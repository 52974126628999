@import 'typography';

#tabContainer {
    @include flex-centered-items;
    flex-wrap: wrap;

    button {
        @include font-style(LatoRegular, 1.4rem, $almost_black);
        padding: 14px 20px;
        text-transform: uppercase;
        border: none;
        background: none;

        &:hover {
            background: $bg_grey_2;
            cursor: pointer;
        }

        &.active {
            @include font-style(LatoBold);
            background: $bg_grey_2;
        }
    }
}
