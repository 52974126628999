@import 'variables';
@import 'typography';

@mixin flex-centered-items($inline: false) {
    @if ($inline) {
        display: inline-flex;
    } @else {
        display: flex;
    }
    align-items: center;
}

@mixin flex-centered-vertically-items {
    display: flex;
    justify-content: center;
}

@mixin flex-stretched-items {
    display: flex;
    align-items: stretch;
}

@mixin flex-full-centered-items {
    @include flex-centered-items;
    justify-content: center;
}

@mixin flex-space-between {
    @include flex-centered-items;
    justify-content: space-between;
}

@mixin flex-space-around {
    @include flex-centered-items;
    justify-content: space-around;
}

@mixin flex-simple-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-centered-column {
    @include flex-simple-column;
    align-items: center;
}

@mixin flex-align-end {
    @include flex-full-centered-items;
    justify-content: flex-end;
}

@mixin btn-shadow {
    box-shadow: 0px 12px 12px #201b2130;
}

@mixin media-layout($breakpoint) {
    @if not map-get($breakpoints, $breakpoint) {
        @media all and (max-width: $breakpoint) {
            @content;
        }
    } @else {
        @media all and (max-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
}

@mixin border-radius($value: 0) {
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
    border-radius: $value;
}

@mixin position-absolute(
    $top: initial,
    $right: initial,
    $bottom: initial,
    $left: initial
) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@for $i from 1 to 10 {
    $i_2: $i * 10 + 5;
    .mr-#{$i * 10} {
        margin-right: #{$i * 10}px !important;
    }
    .mb-#{$i * 10} {
        margin-bottom: #{$i * 10}px !important;
    }
    .mt-#{$i * 10} {
        margin-top: #{$i * 10}px !important;
    }
    .ml-#{$i * 10} {
        margin-left: #{$i * 10}px !important;
    }
    .mr-#{$i_2} {
        margin-right: #{$i_2}px !important;
    }
    .mb-#{$i_2} {
        margin-bottom: #{$i_2}px !important;
    }
    .mt-#{$i_2} {
        margin-top: #{$i_2}px !important;
    }
    .ml-#{$i_2} {
        margin-left: #{$i_2}px !important;
    }

    .pv-#{$i * 10} {
        padding-top: #{$i * 10}px !important;
        padding-bottom: #{$i * 10}px !important;
    }
    .pv-#{$i_2} {
        padding-top: #{$i_2}px !important;
        padding-bottom: #{$i_2}px !important;
    }
    .ph-#{$i * 10} {
        padding-left: #{$i * 10}px !important;
        padding-right: #{$i * 10}px !important;
    }
    .ph-#{$i_2} {
        padding-left: #{$i_2}px !important;
        padding-right: #{$i_2}px !important;
    }
    .pr-#{$i * 10} {
        padding-right: #{$i * 10}px !important;
    }
    .pb-#{$i * 10} {
        padding-bottom: #{$i * 10}px !important;
    }
    .pt-#{$i * 10} {
        padding-top: #{$i * 10}px !important;
    }
    .pl-#{$i * 10} {
        padding-left: #{$i * 10}px !important;
    }
    .pr-#{$i_2} {
        padding-right: #{$i_2}px !important;
    }
    .pb-#{$i_2} {
        padding-bottom: #{$i_2}px !important;
    }
    .pt-#{$i_2} {
        padding-top: #{$i_2}px !important;
    }
    .pl-#{$i_2} {
        padding-left: #{$i_2}px !important;
    }
}
