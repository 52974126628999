@import 'mixins';
@import 'variables';
@import 'typography';

.btn-default {
    @extend %default-btn-style;
    @include btn-shadow;
    background: $almost_black;
    padding: 0 30px;

    &:hover {
        background: $hover_btn_txt;
    }
}
