@import 'variables';
@import 'mixins';
@import 'typography';

.alerts {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    top: 8rem;
    right: 3rem;
    z-index: 1000;

    .alert {
        background-color: $green;
        padding: 2rem 2.5rem;
        border: 3px solid #ffffff;
        border-radius: 6px;
        font-size: 1.6rem;
        color: $almost_black;
        display: flex;
        align-items: center;

        &.hide {
            opacity: 0;
            transition: opacity 300ms;
        }

        .tag {
            @include font-style(LatoBold, 1.6rem, $almost_black);
            margin-right: 0.5rem;
        }

        .text {
            @include font-style(LatoRegular, 1.6rem, $almost_black);
        }

        button {
            background: none;
            border: 0;
            margin-left: 40px;

            &:hover {
                cursor: pointer;
            }

            span {
                font-size: 1.6rem;
            }
        }

        &.alert-success {
            background-color: $bg_success;
        }
        &.alert-error {
            background-color: #ffdada;
        }
        &.alert-warning {
            background-color: $bg_warning;
        }
        &.alert-info {
            background-color: $hover_btn_txt;
            color: #ffffff;
        }
    }
    @include media-layout('mobile') {
        top: 70px;
        right: 0;

        &,
        .alert {
            width: 100%;
            box-sizing: border-box;
        }
    }
}
